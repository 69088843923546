import React from 'react'
import styled from 'styled-components'
import { RotateCard } from './RotateCard'
import AboutCardData from '../Data/AboutCardData.json'
import { ReUseHTwotag } from './ReUseHTwoTag'
import { ReUsePtag } from './ReUsePtag'

const AppAboutRotateCardWapper = styled.div`
  .CardCaniter {
    margin-top: 5%;
    display: flex;
    margin-bottom: 5%;
    padding: 10px;
    flex-flow: wrap;
    justify-content: space-evenly;
    max-width: 1180px;
    text-align: center;
    margin: auto;
  }
  .CardImage {
    width: auto;
    height: 100px;
    margin-top: 70px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    padding-top: 5%;
  }
  .CardPara {
    text-align: left;
  }
  .UpperPara {
    text-align: center;
    padding-bottom: 3rem;
  }
  .upperHeading {
    padding-top: 3rem;
    text-align: center;
  }
  .DisplayNone {
    display: none;
  }
`

export const AppAboutRotateCard = () => {
  return (
    <AppAboutRotateCardWapper>
      <div id="OurValues">
        <ReUseHTwotag Heading="Our Values" HeadingStyle="upperHeading" />
        <ReUsePtag para="A Difference Underlined By... Our Differentiated Business Approach" paraStyle="UpperPara" />
      </div>
      <div className="CardCaniter">
        {AboutCardData.map((data, index) => {
          return (
            <div key={index}>
              <RotateCard
                id={data.id}
                Heading={data.title}
                para={data.para}
                ImagePath={data.ImagePath}
                AltName={data.AltName}
                ParaStyle="CardPara"
                ImageStyle="CardImage"
                HeadingStyle="CardHeading"
                ButtonDisplay="DisplayNone"
              />
            </div>
          )
        })}
      </div>
    </AppAboutRotateCardWapper>
  )
}
