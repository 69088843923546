import React from 'react'
import styled from 'styled-components'
import { Block } from './Block'
import AboutUSdata from '../Data/AboutUSdata.json'
import { Collapsible } from './Collapsible'
import { ReUsePtag } from './ReUsePtag'

const AppAboutAboutUsWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .BrandContain {
    max-width: 1920px;
    margin: auto;
  }
  .BrandSection {
    display: flex;
    justify-content: space-around;
    @media (max-width: 1090px) {
      flex-flow: wrap;
    }
  }
  /* .BrandcontainHeading {
    text-align: center;
  } */
  .AboutPara {
    max-width: 850px;
    padding: 15px 35px 15px 15px;
    margin: auto;
    @media (max-width: 1090px) {
      max-width: 850px;
      padding: 15px 15px 15px 15px;
    }
  }
  .AboutParaLower {
    max-width: 850px;
    padding: 15px 35px 15px 25px;
    margin: auto;
    @media (max-width: 1920px) and (min-width: 1830px) {
      padding: 15px 35px 15px 0px;
    }
    @media (max-width: 1650px) and (min-width: 1501px) {
      max-width: 850px;
      padding: 15px 20px 15px 15px;
    }
    @media (max-width: 1500px) and (min-width: 1392px) {
      max-width: 900px;
      padding: 15px 45px 15px 90px;
    }
    @media (max-width: 1390px) {
      max-width: 800px;
      padding: 15px 35px 15px 25px;
      margin-left: auto;
      margin-right: unset;
    }
    @media (max-width: 1090px) {
      max-width: 850px;
      padding: 15px 15px 15px 15px;
    }
  }
  .BrandSectionNone {
    display: flex;
    justify-content: space-around;
    @media (max-width: 1090px) {
      flex-flow: wrap;
    }
  }
  .BrandImage {
    height: auto;
    width: 45%;
    @media (max-width: 1090px) {
      width: 100%;
    }
  }
  .AboutImageCenter {
    width: 100%;
    @media (max-width: 1090px) {
      width: 100%;
    }
  }
  .BrandcontainHeadingSmall {
    display: none;
    @media (max-width: 1090px) {
      display: block;
      text-align: center;
      padding-bottom: 2%;
    }
  }
  .BrandcontainHeadingBig {
    padding: 0px 0px 3% 15px;
    @media (max-width: 1090px) {
      display: none;
    }
  }
  .AboutParaSection {
    margin-top: 5%;
    @media (max-width: 1090px) {
      margin-top: 0%;
    }
  }
  .iconColor {
    color: #34a1d5;
    padding: 15px;
  }
  .BrandbuttonDisplay {
    display: none;
  }
  .LowerSection {
    max-width: 950px;
    margin-left: auto;
    padding: 0px 0px 15px 0px;
    margin-right: -5px;
    margin-top: -14rem;
    @media (max-width: 1920px) and (min-width: 1830px) {
      margin-top: -16rem;
      margin-right: -10px;
    }
    @media (max-width: 1650px) and (min-width: 1461px) {
      margin-top: -12rem;
      margin-right: -38px;
    }
    @media (max-width: 1460px) and (min-width: 1392px) {
      margin-top: -6rem;
      margin-right: -30px;
    }
    @media (max-width: 1390px) {
      margin-top: 0%;
      margin-top: -6rem;
    }
    @media (max-width: 1090px) {
      margin-right: auto;
      margin-top: auto;
    }
  }
  .AboutButtonCenter {
    padding: 2rem 0rem 2rem 25rem;
    @media (max-width: 1090px) {
      padding-top: 2rem;
      padding-bottom: 2rem;
      padding-right: unset;
      padding-left: unset;
      display: flex;
      justify-content: center;
    }
  }
`

export const AppAboutAboutUs = () => {
  return (
    <AppAboutAboutUsWapper>
      {AboutUSdata.map((data, index) => {
        return (
          <div key={index} id='AboutUs'>
            <Block
              Heading={data.title}
              paraOne={data.para}
              ImagePath={data.ImagePath}
              mainContain='BrandContain'
              containHeading='BrandcontainHeadingSmall'
              containHeadingBig='BrandcontainHeadingBig'
              containParaOne='AboutPara'
              containParaTwo='AboutParaNone'
              containParaThree='AboutParaNone'
              InternalSection='BrandSectionNone'
              containImage='BrandImage'
              altName='BrandImage'
              paraSection='AboutParaSection'
              ButtonDisplay='BrandbuttonDisplay'
              allImage='AboutImageCenter'
            />
            <div className='LowerSection'>
              <Collapsible ButtonName={data.buttonName} ButtonCenter='AboutButtonCenter'>
                <ReUsePtag para={data.paraOne} paraStyle='AboutParaLower' />
                <ReUsePtag para={data.paraTwo} paraStyle='AboutParaLower' />
              </Collapsible>
            </div>
          </div>
        )
      })}
    </AppAboutAboutUsWapper>
  )
}
