import React from 'react'
import styled from 'styled-components'
import { Banner } from './Banner'
import AboutAboutUsBanner from '../Data/AboutAboutUsBanner'

const AppAboutBannerWapper = styled.div`
  .containSection {
    display: flex;
    max-width: 1920px;
    margin: auto;
    padding: 130px 96px 50px 96px;
    justify-content: space-between;
    overflow: hidden;
    @media (max-width: 1390px) {
      padding: 80px 30px;
    }
    @media (max-width: 1090px) {
      padding: 80px 15px;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
  .BannerAboutSectionOne {
  }
  .AboutBannerSectionTwo {
    height: auto;
    width: 48%;
    @media (max-width: 1090px) {
      height: auto;
      width: auto;
      padding-right: unset;
    }
  }
  .BannerAboutHeadingSize {
    max-width: 700px;
    text-align: left;
    padding-bottom: 3%;
    font-size: 2.5rem;
    line-height: 1.30435em;
  }
  .BannerAboutPara {
    display: none;
  }
  .BannerAboutImage {
    width: 100%;
    height: auto;
    margin-top: -2%;
  }
  .BannerAboutLiStyle {
    text-decoration: none;
    list-style-type: none;
    padding: 1%;
    word-break: unset;
  }
  .iconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
  .BannerAboutUlStyle {
    display: grid;
    margin: auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: unset;
  }
`

export const AppAboutBanner = () => {
  return (
    <AppAboutBannerWapper>
      {AboutAboutUsBanner.map((data, index) => {
        return (
          <div key={index}>
            <Banner
              containSection='containSection'
              BannerHeading={data.Title}
              ImagePath={data.ImagePath}
              LiIttemOne={data.LiPonitsOne}
              LiIttemTwo={data.LiPonitsTwo}
              LiIttemThree={data.LiPonitsThree}
              LiIttemFour={data.LiPonitsFour}
              LiIttemFive={data.LiPonitsFive}
              Icon={data.icon}
              IconLast={data.icon}
              BannerSectionOne='BannerAboutSectionOne'
              BannerSectionTwo='AboutBannerSectionTwo'
              BannerHeadingSize='BannerAboutHeadingSize'
              BannerPara='BannerAboutPara'
              BannerImage='BannerAboutImage'
              altName='AboutBannerImage'
              UlStyle='BannerAboutUlStyle'
              LiStyle='BannerAboutLiStyle'
              IconOneStyle='iconColor'
              IconTwoStyle='iconColor'
              IconThreeStyle='iconColor'
              IconFourStyle='iconColor'
              IconFiveStyle='iconColor'
              scrollPathOne='OurValues'
              offsetValue={-100}
              scrollPathTwo='RaindropTeam'
              scrollPathThree='AboutUs'
              scrollPathFour='OurStory'
              scrollPathFive='OurBrand'
            />
          </div>
        )
      })}
    </AppAboutBannerWapper>
  )
}
