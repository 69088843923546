import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    Title: 'Reinventing the Way Businesses Take Control of their Spend. It’s Raindrop!',
    ImagePath: 'https://storage.googleapis.com/raindroppublic/website_data/BannerAboutUs.png',
    LiPonitsOne: 'Our Values',
    LiPonitsTwo: 'Raindrop Team',
    LiPonitsThree: 'About Us',
    LiPonitsFour: 'Our Story',
    LiPonitsFive: 'Our Brand',
    icon: <FaArrowCircleRight />,
  },
]
