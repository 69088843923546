import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { ReuseImgTag } from './ReuseImgTag'
import { ReUseHTwotag } from './ReUseHTwoTag'
import { Button } from './Button'
import { LinkHubspot } from './LinkHubspot'
import AboutMeetTeamData from '../Data/AboutMeetTeamData.json'

const AppMeetOurWappper = styled.div`
  max-width: 1920px;
  margin: auto;
  .MeetOurTeamSection {
    padding: 1rem;
  }
  .MeetOurTeamHeading {
    text-align: center;
  }
  .MeetOurTeamHeadingPara {
    text-align: left;
    max-width: 820px;
    margin: auto;
  }
  .UnderTeamSection {
    display: flex;
    justify-content: space-between;
    padding: 5rem 5rem 1rem 7rem;
    @media (max-width: 1090px) {
      flex-flow: wrap;
      padding: 1rem 1rem 1rem 1rem;
    }
  }
  .ImageSection {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  .MeetTeamImageStyle {
    width: 70%;
    height: 450px;
    border-radius: 18px;
    box-shadow: 0px 3px 7px 0 rgb(160 161 163 / 88%);
    @media (max-width: 1650px) {
      width: unset;
    }
    @media (max-width: 1450px) {
      height: 360px;
    }
    @media (max-width: 1090px) {
      display: none;
    }
  }
  .UnderMeetOurTeamHeading {
    text-align: left;
    @media (max-width: 1090px) {
      text-align: center;
    }
  }
  .UnderMeetOurTeamHeadingPara {
    text-align: left;
    max-width: 1000px;
    padding-top: 2rem;
  }
  .ButtonMeetThe {
    display: flex;
    justify-content: center;
    padding-top: 2rem;
  }
  .MobileMeetTeamImageStyle {
    display: none;
    @media (max-width: 1090px) {
      display: flex;
      width: 100%;
      height: auto;
    }
  }
`

export const AppMeetOur = () => {
  return (
    <AppMeetOurWappper>
      {AboutMeetTeamData.map((data, index) => {
        return (
          <div key={index} id="RaindropTeam">
            <div className="UnderTeamSection">
              <div>
                <ReUseHTwotag Heading={data.titleTwo} HeadingStyle="UnderMeetOurTeamHeading" />
                <ReuseImgTag ImageStyle="MobileMeetTeamImageStyle" ImagePath={data.ImagePath} AltName={data.AltName} />
                <ReUsePtag para={data.paraTwo} paraStyle="UnderMeetOurTeamHeadingPara" />
                <div className="ButtonMeetThe">
                  <LinkHubspot to="/about/team">
                    <span className="sr-only">Jump to packages</span>
                    <Button cta="Meet The Team" label="Meet The Team" type="submit" className="demobutton" />
                  </LinkHubspot>
                </div>
              </div>
              <div className="ImageSection">
                <ReuseImgTag ImageStyle="MeetTeamImageStyle" ImagePath={data.ImagePath} AltName={data.AltName} />
              </div>
            </div>
          </div>
        )
      })}
    </AppMeetOurWappper>
  )
}
