import React from 'react'
import styled from 'styled-components'
import { Block } from './Block'
import AboutBrandData from '../Data/AboutBrandData.json'

const AppAboutBrandWapper = styled.div`
  .BrandContain {
    max-width: 1920px;
    margin: auto;
    padding-bottom: 3rem;
  }
  .BrandSection {
    display: flex;
    justify-content: space-around;
    @media (max-width: 1090px) {
      flex-flow: wrap;
    }
  }
  /* .BrandcontainHeading {
    text-align: center;
  } */
  .BrandPara {
    max-width: 850px;
    padding: 15px 35px 15px 15px;
    @media (max-width: 1090px) {
      max-width: 850px;
      padding: 15px 15px 15px 15px;
    }
  }
  .BrandSectionNone {
    display: flex;
    justify-content: space-around;
    @media (max-width: 1090px) {
      flex-flow: wrap;
    }
  }
  .BrandImage {
    height: auto;
  }
  .BrandImageCenter {
    width: 75%;
    padding-left: 4rem;
    @media (max-width: 1090px) {
      width: 100%;
      padding-left: unset;
    }
  }
  .BrandcontainHeadingSmall {
    display: none;
    @media (max-width: 1090px) {
      display: block;
      text-align: center;
      padding-bottom: 2%;
    }
  }
  .BrandcontainHeadingBig {
    padding: 0px 0px 3% 15px;
    @media (max-width: 1090px) {
      display: none;
    }
  }
  .BrandparaSection {
    margin-top: 8%;
    @media (max-width: 1090px) {
      margin-top: 0%;
    }
  }
  .iconColor {
    color: #34a1d5;
    padding: 15px;
  }
  .BrandbuttonDisplay {
    display: none;
  }
`

export const AppAboutBrand = () => {
  return (
    <AppAboutBrandWapper>
      {AboutBrandData.map((data, index) => {
        return (
          <div key={index} id='OurBrand'>
            <Block
              Heading={data.Heading}
              paraOne={data.para}
              ImagePath={data.ImagePath}
              mainContain='BrandContain'
              containHeading='BrandcontainHeadingSmall'
              containHeadingBig='BrandcontainHeadingBig'
              containParaOne='BrandPara'
              containParaTwo='BrandParaNone'
              containParaThree='BrandParaNone'
              InternalSection='BrandSectionNone'
              containImage='BrandImage'
              altName='BrandImage'
              paraSection='BrandparaSection'
              ButtonDisplay='BrandbuttonDisplay'
              allImage='BrandImageCenter'
            />
          </div>
        )
      })}
    </AppAboutBrandWapper>
  )
}
