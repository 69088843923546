import React from 'react'
import styled from 'styled-components'
import { Block } from './Block'
import AboutStoryData from '../Data/AboutStoryData.json'

const AppAboutStoryWpper = styled.div`
  padding-top: 2rem;
  .StoryContain {
    max-width: 1920px;
    margin: auto;
  }
  .StorySection {
    display: flex;
    justify-content: space-around;
    margin-top: 5%;
    @media (max-width: 1090px) {
      flex-flow: wrap;
      margin-top: auto;
    }
  }
  /* .StoryHeading {
    text-align: center;
  } */
  .StoryHeadingSmall {
    display: none;
    @media (max-width: 1090px) {
      display: block;
      text-align: center;
      padding-bottom: 2%;
    }
  }
  .StoryHeadingBig {
    padding: 0px 0px 3% 40px;
    @media (max-width: 1090px) {
      display: none;
    }
  }
  .StoryImage {
    height: auto;
    order: 1;
    @media (max-width: 1460px) and (min-width: 1392px) {
      margin-top: 2%;
    }
    @media (max-width: 1090px) {
      order: 0;
    }
  }
  .StoryImageCenter {
    width: 75%;
    display: flex;
    float: right;
    @media (max-width: 1090px) {
      width: 100%;
      float: unset;
    }
  }
  .StroyParaSection {
    order: 0;
    margin-top: 3%;
    padding-left: 5rem;
    @media (max-width: 1650px) {
      margin-top: 0%;
      padding-left: unset;
    }
  }
  .StoryPara {
    max-width: 870px;
    padding: 15px 0px 15px 40px;
    @media (max-width: 1650px) {
      max-width: 750px;
    }
    @media (max-width: 1090px) {
      padding: 15px 15px 15px 15px;
    }
  }
  .StoryParaNone {
    display: none;
  }
  .StorybuttonDisplay {
    display: none;
  }
`

export const AppAboutStory = () => {
  return (
    <AppAboutStoryWpper>
      {AboutStoryData.map((data, index) => {
        return (
          <div key={index} id='OurStory'>
            <Block
              Heading={data.title}
              paraOne={data.para}
              ImagePath={data.ImagePath}
              mainContain='StoryContain'
              containHeading='StoryHeadingSmall'
              containHeadingBig='StoryHeadingBig'
              containParaOne='StoryPara'
              containParaTwo='StoryParaNone'
              containParaThree='StoryParaNone'
              InternalSection='StorySection'
              containImage='StoryImage'
              altName='StoryImage'
              paraSection='StroyParaSection'
              ButtonDisplay='StorybuttonDisplay'
              allImage='StoryImageCenter'
            />
          </div>
        )
      })}
    </AppAboutStoryWpper>
  )
}
