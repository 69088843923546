import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'
import { motion, useAnimation } from 'framer-motion'
import { Button, ButtonCompact } from './Button'
const CollapsibleWapper = styled.div`
  button {
    text-transform: capitalize;
  }

  button:focus {
    outline: none;
  }
`

export const Collapsible = (props: any) => {
  const [isOpen, setIsOpen] = useState(false)

  const controls = useAnimation()
  const [ref, inView] = useInView({
    // Percentage of item in view to trigger animation
    threshold: 0.25,
  })

  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  return (
    <CollapsibleWapper>
      <motion.div
        className='collapsible'
        ref={ref}
        animate={controls}
        initial='hidden'
        variants={{
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 25 },
        }}
        transition={{ ease: 'easeOut', duration: 1.25, delay: 0.35 }}>
        {isOpen && <div className='content'>{props.children}</div>}
        <div className={props.ButtonCenter}>
          <ButtonCompact cta={`${isOpen ? 'Read Less' : 'Read More'}`} onClick={() => setIsOpen(!isOpen)} />
        </div>
      </motion.div>
    </CollapsibleWapper>
  )
}
